.sideBarCard {
    background-color: #fff;
    border-radius: 0.3rem;
    padding: 1rem;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);

    margin: 1rem 0 1.2rem 0;
}

.titleCategories {
    font-size: 1rem;
    font-weight: 500;
}

.categoryList {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        font-size: 0.9rem;
        line-height: 1.4rem;
    }
}
