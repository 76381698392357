.productTitle {
    font-size: 1.15rem;
    font-weight: 700;
    margin-top: 0.2rem;
    @media (min-width: 992px) {
        font-size: 1.5rem;
    }
}

.actionWrapper {
    display: flex;
    margin-top: 0.5rem;
    width: 100%;

    @media (min-width: 768px) and (max-width: 1000px) {
        flex-direction: column;

        .addToCartBtn {
            margin-left: 0rem;
            margin-top: 1rem;
        }
    }

    .decrement {
        padding-left: 1.2rem;
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
    }

    .increment {
        padding-right: 1.2rem;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

    svg {
        color: #333;
    }

    .inputGroup {
        width: 160px;
        @media (max-width: 767.98px) {
            width: 40%;
        }
    }

    input {
        width: 60px;
        background: #333;
        color: #fff;
        font-size: 1.4rem;
        border: 1px solid #333 !important;
    }
}

.productDescription {
    font-size: 0.85rem;
}

.productSpecifications,
.descriptionWrapper {
    background-color: #fff;
    padding: 2rem 0;

    img {
        max-width: 100%;
        max-height: none;
        height: auto !important;
    }
}

.productTableStripped {
    font-size: 0.94rem;

    td {
        padding: 0.75rem 1rem;
    }

    --bs-table-bg: #fff;
    --bs-table-border-color: #fff;
}

.tableCaracteristics {
    position: relative;
    height: 300px;
    overflow-y: hidden;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 33%, rgb(255, 255, 255) 100%);
        background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 33%, rgb(255, 255, 255) 100%);
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 33%, rgb(255, 255, 255) 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00FFFFFF", endColorstr="#FFFFFFFF", GradientType=0);
        pointer-events: none;
        z-index: 1;
    }
}

.productOldPrice {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: bold;
}

.productPrice {
    color: #e00000;
    font-weight: bold;
    font-size: 1.4rem;
}

.addToCartBtn {
    padding: 0.5rem 3rem;
    margin-left: 1rem;

    @media (max-width: 767.98px) {
        padding: 0.5rem 2rem;
    }
}

.doorProductWrapper {
    border: 1px solid #999;
    padding: 15px;
    margin-bottom: 20px;

    .stepTitle {
        font-family: Raleway;
        font-weight: 400;
        font-size: 22px;
        color: #121111;
        padding: 15px 0;
    }

    .doorPrice {
        font-family: Montserrat;
        font-weight: 700;
        font-size: 24px;
    }

    .doorImageWrapper {
        border: 1px solid #999;
        padding: 15px 15px 30px 30px;
        background-color: #ececec;
        position: relative;

        .doorHeight {
            color: #333;
            font-weight: 700;
            font-size: 1em;
            position: absolute;
            right: calc(100% - 70px);
            top: 50%;
            width: 100px;
            transform: rotate(90deg);
            transform-origin: 50% 50%;
        }

        .doorLength {
            color: #333;
            font-weight: 700;
            font-size: 1em;
            position: absolute;
            left: 50%;
            top: calc(100% - 30px);
            width: 100px;
        }
    }

    label {
        margin-left: 4px;
        vertical-align: text-top;
    }

    input[type="radio"] {
        appearance: none;
        margin-right: 4px;
        box-sizing: content-box;
        vertical-align: middle;
        position: relative;
        background: none !important;
        border: none !important;
        width: auto;
        height: auto;

        &:before {
            content: "";
            display: block;
            box-sizing: content-box;
            width: 16px;
            height: 16px;
            border: 2px solid #979797;
            border-radius: 4px;
        }

        &:checked:after {
            content: "";
            display: block;
            position: absolute;
            box-sizing: content-box;
            top: 50%;
            left: 50%;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            background-color: #000;
            width: 16px;
            height: 16px;
            border-radius: 100vh;
            transform: translate(-50%, -50%) scale(0.8);
        }
    }

    input[type="checkbox"] {
        appearance: none;
        margin-right: 4px;
        box-sizing: content-box;
        vertical-align: middle;
        position: relative;
        background: none !important;
        border: none !important;
        width: auto;
        height: auto;

        &:before {
            content: "";
            display: block;
            box-sizing: content-box;
            width: 16px;
            height: 16px;
            border: 2px solid #979797;
            border-radius: 4px;

            &:checked {
                border-color: #000;
            }
        }

        &:checked:after {
            content: "✔";
            display: block;
            position: absolute;
            box-sizing: content-box;
            top: -1px;
            left: 4px;
        }
    }
}

.dimension {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .dimensionTitle {
        background-color: #3c3f48;
        color: #fff;
        font-family: Raleway;
        font-size: 14px;
        text-align: center;
        width: 130px;
        line-height: 48px;
        height: 50px;
    }

    .inputWrapper {
        position: relative;

        input {
            border: 1px solid #3c3f48;
            border-radius: unset;
            width: 100px;
            height: 50px;
        }

        .afterInput {
            position: absolute;
            top: 12px;
            right: 5px;
        }
    }
}
