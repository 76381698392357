.paginationWrapper {
    margin-top: 1rem;
    [class="page-item"] {
        [class="active"] {
            color: var(--primary-color) !important;
        }
        &.disabled {
            cursor: not-allowed;
        }
    }
    [class="page-link"] {
        color: #333 !important;
    }

    .btn-prev {
        padding: 0;
        border: none;
        margin-right: 0.4rem;
    }

    .pagination__page-next {
        padding: 0;
        border: none;
        margin-left: 0.4rem;
    }
}
