.readOrHide {
    color: var(--primary-color) !important;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    margin-top: 5px;
    border: 0;
    padding: 0;
}

.text {
    display: inline;
    font-size: 14px;
    color: #111;
    font-weight: 300;
}
